import React, { Fragment } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { Menu, Transition, Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import { useAuth } from 'react-oidc-context';

export default function Layout(props) {

  const auth = useAuth();

  const currentRoute = useLocation().pathname;
  const email = props.user.email;
  const navigation = [
    { name: 'Meal plan', href: '/', current: true },
    { name: 'Meals', href: '/meals', current: false }
  ]
  const userNavigation = [
    { name: 'Manage planners', href: '#' },
    { name: 'Sign out', href: '#' },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function isCurrentRoute(item) {
    return currentRoute === item.href;
  }

  return (
    <>
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
          <Disclosure as="nav" className="bg-indigo-600 border-b border-indigo-300 border-opacity-25 lg:border-none">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                  <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                    <div className="px-2 flex items-center lg:px-0">
                      <div className="flex-shrink-0">
                        <img
                          className="block h-8 w-8"
                          src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden lg:block lg:ml-10">
                        <div className="flex space-x-4">
                          {navigation.map((item) => (
                            <Link to={item.href} key={item.name}>
                              <button
                                className={classNames(
                                  isCurrentRoute(item)
                                    ? 'bg-indigo-700 text-white'
                                    : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                  'rounded-md py-2 px-3 text-sm font-medium'
                                )}
                                aria-current={isCurrentRoute(item) ? 'page' : undefined}
                              >
                                {item.name}
                              </button>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex lg:hidden">
                      <Disclosure.Button className="bg-indigo-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="hidden lg:block lg:ml-4">
                      <div className="flex items-center">
                        <span className="text-sm text-white">{email}</span>
                        <Menu as="div" className="ml-3 relative flex-shrink-0">
                          <div>
                            <Menu.Button className="bg-indigo-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                <a href="#" className="block py-2 px-4 text-sm text-gray-700">Manage planners</a>
                              </Menu.Item>
                              <Menu.Item>
                                <a href="#" className="block py-2 px-4 text-sm text-gray-700" onClick={() => auth.removeUser()}>Sign out</a>
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-indigo-700 text-white'
                            : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                          'block rounded-md py-2 px-3 text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="pt-4 pb-3 border-t border-indigo-700">
                    <div className="px-5 flex items-center">
                      <div className="flex-shrink-0">
                        <UserCircleIcon className="h-5 w-5 text-medium font-medium text-indigo-300" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <div className="text-medium font-medium text-indigo-300">{email}</div>
                      </div>
                    </div>
                    <Disclosure.Button
                      as="a"
                      href="#"
                      className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                    >
                      Manage planners
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="#"
                      onClick={() => auth.removeUser()}
                      className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <main className="-mt-24 pb-8">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <Outlet />
          </div>
        </main>

      </div >
    </>
  );
}