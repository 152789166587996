import React, { useState, useEffect } from 'react';
import { getMeal, updateMeal, deleteMeal } from '../meals-api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from "react-oidc-context";

import Meal from '../models/Meal';

export default function EditMeal(props) {

  const [state, setState] = useState(new Meal());
  const navigate = useNavigate();
  const params = useParams();

  const auth = useAuth();
  const plannerId = props.planner.id;

  useEffect(() => {
    (async () => {
      const meal = await getMeal(plannerId, params.mealId, auth.user?.access_token);
      setState(meal);
    })();
  }, []);

  function handleChange(event) {
    let name = event.target.name
    setState({
      ...state,
      [name]: event.target.value
    })
  }

  async function save() {
    await updateMeal(plannerId, state, auth.user?.access_token);
    navigate('/meals');
  }

  async function deleteItem() {
    await deleteMeal(plannerId, state.id, auth.user?.access_token);
    navigate('/meals');
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Edit meal</h2>
          </div>
        </div>
        <form className="space-y-8 divide-y divide-gray-200 py-5">
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={state.name ?? ''}
                      onChange={handleChange}
                      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      value={state.description ?? ''}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
                    Frequency
                  </label>
                  <div className="mt-1">
                    <select
                      id="frequency"
                      name="frequency"
                      value={state.frequency ?? ''}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    >
                      <option>Weekly</option>
                      <option>Biweekly</option>
                      <option>Monthly</option>
                      <option>Every couple months</option>
                      <option>Occasionally</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div className="pt-5">
            <div className="flex items-center justify-between">
              {/* <div className="justify-start"> */}
              <div className="ml-4 mt-2">
                <button
                  type="button"
                  onClick={() => { deleteItem() }}
                  className="ml-3 inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Delete
                </button>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <Link to="/meals">
                  <button
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="button"
                  onClick={() => { save() }}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );

}
