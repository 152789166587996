import React, { Fragment, useState, useEffect } from 'react';
import { getMeals, createMealPlan, updateMealPlan } from '../meals-api';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import '@fortawesome/fontawesome-free/css/all.css';
import { Combobox, Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Meal from '../models/Meal';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';


export default function MealPlan(props) {

  const [goal, setGoal] = useState(0);
  const [plannedMeals, setPlannedMeals] = useState([]);
  const [meals, setMeals] = useState([]);

  const [query, setQuery] = useState('');
  const [selectedMeal, setSelectedMeal] = useState();

  const [editingMeal, setEditingMeal] = useState('');

  const [note, setNote] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = auth.user?.access_token;

    if (!props.planner?.id) {
      navigate('/create');
    }
    else {
      (async () => {
        setGoal(props.planner.mealPlan?.goal);
        setPlannedMeals(props.planner.mealPlan?.meals || []);
        const mealsData = await getMeals(props.planner.id, token);
        setMeals(mealsData);
      })();
    }
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function enableEditing(meal) {
    setNote(meal.note);
    setEditingMeal(meal.id);
  }

  function handleNoteChange(event) {
    setNote(event.target.value);
  }

  const filteredMeals =
    query === ''
      ? meals
      : meals.filter((meal) => {
        return meal.name.toLowerCase().includes(query.toLowerCase());
      })

  async function addMeal() {
    const updatedPlannedMeals = [...plannedMeals];
    updatedPlannedMeals.push(selectedMeal);

    const updatedMealPlan = await updateMealPlan(props.planner.id, { goal: goal, meals: updatedPlannedMeals }, auth.user?.access_token);
    setPlannedMeals(updatedMealPlan.meals);

    setSelectedMeal(undefined);
  }

  async function removeMeal(id) {
    const updatedPlannedMeals = plannedMeals.filter(x => {
      return x.id != id;
    });
    const updatedMealPlan = await updateMealPlan(props.planner.id, { goal: goal, meals: updatedPlannedMeals }, auth.user?.access_token);
    setPlannedMeals(updatedMealPlan.meals);
  }

  async function markComplete(id) {
    const updatedPlannedMeals = [...plannedMeals];
    updatedPlannedMeals.forEach((meal) => {
      if (meal.id === id) {
        meal.completed = true;
      }
    });

    const updatedMealPlan = await updateMealPlan(props.planner.id, { goal: goal, meals: updatedPlannedMeals }, auth.user?.access_token);
    setPlannedMeals(updatedMealPlan.meals);
  }

  async function nextPlan() {
    const updatedMealPlan = await createMealPlan(props.planner.id, { goal: goal, meals: plannedMeals }, auth.user?.access_token);
    setPlannedMeals(updatedMealPlan.meals);
  }

  async function blah(event) {
    if (event.key === 'Enter') {
      const updatedPlannedMeals = [...plannedMeals];
      updatedPlannedMeals.forEach((meal) => {
        if (meal.id === editingMeal) {
          meal.note = note;
        }
      });

      const updatedMealPlan = await updateMealPlan(props.planner.id, { goal: goal, meals: updatedPlannedMeals }, auth.user?.access_token);
      setPlannedMeals(updatedMealPlan.meals);
      setEditingMeal('');
    }
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 overflow-y-auto h-full">
        <div className="h-96 brounded-lg">

          <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Meal plan</h2>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <button type="button"
                onClick={() => { nextPlan() }}
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Next plan
              </button>
            </div>
          </div>

          {plannedMeals.length > 0 ?
            <div className="mt-10">
              <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {plannedMeals?.map((meal) => (
                  <li key={meal.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow relative">
                    <div className="flex w-full items-center justify-between space-x-6 p-4">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <Link to={`/meals/${meal.id}/edit`}>
                            <h3 className="truncate text-sm font-medium text-gray-900">{meal.name}</h3>
                          </Link>
                          {meal.completed && <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>}
                        </div>
                        {meal.id !== editingMeal && <p className="mt-1 truncate text-sm text-gray-500" onClick={() => enableEditing(meal)}>{meal.note ? meal.note : 'Click to enter a note'}</p>}
                        {meal.id === editingMeal && <div className="mt-1 sm:col-span-2 sm:mt-0" onKeyUp={blah}>
                          <input
                            type="text"
                            name="note"
                            id="note"
                            value={note ?? ''}
                            onChange={handleNoteChange}
                            className="block w-full h-5 max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-xs sm:leading-6"
                          />
                        </div>}
                      </div>

                      <Menu as="div" className="inline-block text-left">
                        <div className="flex-shrink-0 pr-2">
                          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                    onClick={() => { markComplete(meal.id) }}
                                  >
                                    Mark complete
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                    onClick={() => { removeMeal(meal.id) }}
                                  >
                                    Remove from planner
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            :
            <div className="p-6">
              <p className="mt-1 text-sm text-gray-500">No meals have been added to the meal plan.</p>
            </div>
          }


          <div className="p-6">
            <h3 className="text-xl font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">Add meal to planner</h3>
            <form className="mt-5 sm:flex sm:items-center">
              <div className="w-full sm:max-w-xs">
                <Combobox as="div" value={selectedMeal} onChange={setSelectedMeal}>
                  <div className="relative mt-1">
                    <Combobox.Input
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(selectedMeal) => (selectedMeal as Meal)?.name}
                      placeholder="Enter meal name"
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>

                    {filteredMeals.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredMeals.map((filteredMeal) => (
                          <Combobox.Option
                            key={filteredMeal.id}
                            value={filteredMeal}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span className={classNames('block truncate', selected && 'font-semibold')}>{filteredMeal.name}</span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                      active ? 'text-white' : 'text-indigo-600'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
              <button
                type="button"
                onClick={addMeal}
                className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Add
              </button>

            </form>
          </div>
        </div>
      </div>

    </>
  );

}
