import React from 'react';
import App from './App';
import Home from './Home';
import { useAuth } from 'react-oidc-context';

export default function AuthWrapper() {
  const auth = useAuth();

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading || auth.error) {
    return <div>Loading...</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <div>
        <App />
      </div>
    );
  }
  else {
    return (
      <div>
        <Home />
      </div>
    )
  }

}
