import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { getPlanners } from './meals-api';
import MealPlan from './components/MealPlan';
import Layout from './components/Layout';
import Meals from './components/Meals';
import AddMeal from './components/AddMeal';
import EditMeal from './components/EditMeal';
import CreatePlanner from './components/CreatePlanner';
import Planner from './models/Planner';
import Home from './Home';

export default function App() {

  const auth = useAuth();
  const [user, setUser] = useState({});
  const [planner, setPlanner] = useState({});
  const [plannerLoaded, setPlannerLoaded] = useState(false);

  useEffect(() => {
    const token = auth.user?.access_token;

    (async () => {
      const planners = await getPlanners(token);

      if (planners) {
        const defaultPlanner = getDefaultPlanner(planners);
        setUser(defaultPlanner?.users[0]);
        setPlanner(defaultPlanner);
      }

      setPlannerLoaded(true);
    })();
  }, []);

  function handlePlannerChange(planner: Planner) {
    setPlanner(planner);
    setPlannerLoaded(true);
  }

  function getDefaultPlanner(planners) {
    if (planners?.length > 1) {
      return planners?.filter((item) => item.users[0].defaultPlanner)[0];
    }
    return planners[0];
  }

  return (
    plannerLoaded &&
    <Router>
      <Routes>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/" element={<Layout user={user} />}>
          <Route index element={<MealPlan planner={planner} />}></Route>
          <Route path="/create" element={<CreatePlanner handlePlannerChange={handlePlannerChange} />}></Route>
          <Route path="/mealplan" element={<MealPlan planner={planner} />}></Route>
          <Route path="/meals" element={<Meals planner={planner} handlePlannerChange={handlePlannerChange} />}></Route>
          <Route path="/meals/add" element={<AddMeal planner={planner} />}></Route>
          <Route path="/meals/:mealId/edit" element={<EditMeal planner={planner} />}></Route>
        </Route>
      </Routes>
    </Router >
  )

}
