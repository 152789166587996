import React, { useEffect, useState } from 'react';
import { getMeals, updateMealPlan } from '../meals-api';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import Meal from '../models/Meal';

export default function Meals(props) {

  const [meals, setMeals] = useState([]);
  const [page, setPage] = useState(0);
  const [paginatedMeals, setPaginatedMeals] = useState([]);

  const auth = useAuth();
  const plannerId = props.planner.id;
  const mealsPerPage = 9;

  useEffect(() => {
    const token = auth.user?.access_token;

    (async () => {
      const mealsData = await getMeals(plannerId, token);
      setMeals(mealsData);
      setPaginatedMeals(paginateMeals(mealsData));
    })();
  }, []);

  function handleSearchChange(event) {
    const queryString = event.target.value;
    setPaginatedMeals(paginateMeals(queryString ? searchMeals(queryString) : [...meals]));
  }

  async function addMealToMealPlan(meal) {
    const updatedPlanner = { ...props.planner };
    updatedPlanner.mealPlan.meals.push(meal);

    await updateMealPlan(props.planner.id,
      { goal: props.planner.mealPlan.goal, meals: updatedPlanner.mealPlan.meals }, auth.user?.access_token);
    props.handlePlannerChange(updatedPlanner);
  }

  function isOnMealPlan(mealId) {
    const plannedMeals = props.planner.mealPlan.meals?.filter(meal => meal.id === mealId);
    return plannedMeals?.length > 0;
  }

  function searchMeals(queryString): Meal[] {
    const regex = new RegExp(queryString.toLowerCase());
    return meals.filter(meal => meal.name.toLowerCase().search(regex) !== -1);
  }

  function paginateMeals(meals) {
    const numPages = Math.ceil(meals.length / mealsPerPage);
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(meals.slice(i * mealsPerPage, (i + 1) * mealsPerPage));
    }
    return pages;
  }

  function nextPage() {
    setPage(page + 1);
  }

  function previousPage() {
    setPage(page - 1);
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 overflow-y-auto h-full">
        {meals.length > 0 ?
          <div className="h-96 brounded-lg">
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Meals</h2>
              </div>
              <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                <Link to="/meals/add">
                  <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Add meal
                  </button>
                </Link>
              </div>
            </div>

            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    onChange={handleSearchChange}
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={searchMeals}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 p-5">
              {paginatedMeals.length > 0 && paginatedMeals[page].map((meal) => (
                <li key={meal.id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                  <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">

                        <div className="flex-1 min-w-0">
                          <Link to={`/meals/${meal.id}/edit`}>
                            <h3 className="text-gray-900 text-sm font-medium truncate">{meal.name}</h3>
                          </Link>
                        </div>
                        {isOnMealPlan(meal.id) ?
                          <h4 className="text-green-500 text-sm font-semibold">Planned</h4>
                          :
                          <div>
                            <a href="#" onClick={() => { addMealToMealPlan(meal) }} className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                              <i className="fa-solid fa-plus"></i>
                            </a>
                          </div>
                        }
                      </div>
                      <p className="mt-1 text-gray-500 text-sm truncate">{meal.description}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <nav
              className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                {paginatedMeals.length > 0 ? <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{page * mealsPerPage + 1}</span> to <span className="font-medium">{page * mealsPerPage + paginatedMeals[page].length}</span> of{' '}
                  <span className="font-medium">{paginatedMeals.flat().length}</span> meals
                </p>
                  :
                  <p className="text-sm text-gray-700">
                    No meals match search criteria
                  </p>
                }
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                {page > 0 && <a
                  href="#"
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={previousPage}
                >
                  Previous
                </a>}
                {page < paginatedMeals.length - 1 &&
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    onClick={nextPage}
                  >
                    Next
                  </a>}
              </div>
            </nav>
          </div> :

          <div className="h-96 brounded-lg">
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Meals</h2>
              </div>
            </div>
            <div>
              <p className="mt-1 text-sm text-gray-500">Get started by adding some meals.</p>
              <div className="mt-6">
                <Link to="/meals/add">
                  <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Add meal
                  </button>
                </Link>
              </div>
            </div>

          </div>
        }
      </div>
    </>
  );

}
