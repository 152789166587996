import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider, } from 'react-oidc-context';
import AuthWrapper from './AuthWrapper';

const oidcConfig = {
  authority: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_EmsPMyinW',
  client_id: '36329m3irru3fjqjsh1tpb4r0e',
  redirect_uri: `${process.env.HOST}/redirect`,
  response_type: 'code',
  scope: 'email openid dev-lazy-eater/plan.all',
  onSigninCallback: () => {
    window.history.replaceState(
      {},
      document.title,
      '/'
    )
  }
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AuthWrapper />
    </AuthProvider>
  </React.StrictMode>
);
