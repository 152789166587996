import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { createPlanner } from '../meals-api';
import User from '../models/User';

export default function CreatePlanner(props) {
  const [planner, setPlanner] = useState({ name: '', users: [] });
  const [collaboratorEmail, setCollaboratorEmail] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();

  function handlePlannerChange(event) {
    let name = event.target.name;
    setPlanner({
      ...planner,
      [name]: event.target.value
    });
  }

  function handleCollaboratorChange(event) {
    setCollaboratorEmail(event.target.value);
  }

  function addCollaborator() {
    if (collaboratorEmail) {
      const updatedState = { ...planner };
      updatedState.users.push(new User(collaboratorEmail, 'Collaborator'));
      setCollaboratorEmail('');
      setPlanner(updatedState);
    }
  }

  function removeCollaborator(collaboratorToRemove) {
    const updatedState = { ...planner };
    updatedState.users = planner.users.filter(collaborator => collaborator.email !== collaboratorToRemove);
    setPlanner(updatedState);
  }

  async function create() {
    const newPlanner = await createPlanner(planner, auth.user?.access_token);
    props.handlePlannerChange(newPlanner);
    navigate('/meals', { replace: true });
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Create Planner</h2>
            <form className="space-y-8 divide-y divide-gray-200 py-5">
              <div className="space-y-8 divide-y divide-gray-200">
                <div>
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Planner</h3>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={handlePlannerChange}
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Collaborators</h3>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      {planner.users.length > 0 ?
                        <ul role="list" className="divide-y divide-gray-200">
                          {planner.users.map((collaborator) => (
                            <li key={collaborator} className="flex py-4">
                              <div className="ml-3">
                                <p className="text-sm text-gray-500">{collaborator.email}</p>
                              </div>
                              <div>
                                <a href="#" onClick={() => { removeCollaborator(collaborator.email) }} className="inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                                  <i className="fa-solid fa-x"></i>
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                        : (<p className="mt-1 max-w-2xl text-sm text-gray-500">
                          No collaborators.
                        </p>)
                      }
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Add collaborator
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                          <input
                            type="collaborator"
                            name="collaborator"
                            id="collaborator"
                            className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Email address"
                            onChange={handleCollaboratorChange}
                            value={collaboratorEmail}
                          />
                        </div>
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                          onClick={() => { addCollaborator() }}
                        >
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pt-5">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Beta</h3>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Code
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="betaCode"
                          id="betaCode"
                          onChange={handlePlannerChange}
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <Link to="/home">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => { create() }}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div >
        </div >

      </div >
    </>
  );
}