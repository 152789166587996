import MealPlan from './models/MealPlan';
import axios from 'axios';

const apiUrl = process.env.API_ENDPOINT;

export async function createPlanner(plannerId, token) {
  return await postRequest(`${apiUrl}/planners`, plannerId, token);
}

export async function getPlanners(token) {
  return await getRequest(`${apiUrl}/planners`, token);
}

export async function getPlanner(plannerId, token) {
  return await getRequest(`${apiUrl}/planners/${plannerId}`, token);
}

export async function getMeals(plannerId, token) {
  return await getRequest(`${apiUrl}/planners/${plannerId}/meals`, token);
}

export async function getMeal(plannerId, mealId, token) {
  return await getRequest(`${apiUrl}/planners/${plannerId}/meals/${mealId}`, token);
}

export async function updateMealPlan(plannerId, mealPlan: MealPlan, token: string) {
  return await putRequest(`${apiUrl}/planners/${plannerId}/mealPlan`, mealPlan, token);
}

export async function addMeal(plannerId, meal, token) {
  return await postRequest(`${apiUrl}/planners/${plannerId}/meals`, meal, token);
}

export async function updateMeal(plannerId, meal, token) {
  return await putRequest(`$${apiUrl}/planners/${plannerId}/meals/${meal.id}`, meal, token);
}

export async function deleteMeal(plannerId, mealId, token) {
  return await deleteRequest(`${apiUrl}/planners/${plannerId}/meals/${mealId}`, token);
}

export async function createMealPlan(plannerId, mealPlan: MealPlan, token: string) {
  return await postRequest(`${apiUrl}/planners/${plannerId}/mealPlan`, mealPlan, token);
}

async function getRequest(url: string, token: string) {
  try {
    const response = await axios.get(url, { headers: getHeaders(token) });
    return response.data;
  }
  catch (error) {
    console.log(error);
  }
}

async function putRequest(url: string, body, token: string) {
  try {
    const response = await axios.put(url, body, { headers: getHeaders(token) });
    return response.data;
  }
  catch (error) {
    console.log(error);
  }
}

async function postRequest(url: string, body, token: string) {
  try {
    const response = await axios.post(url, body, { headers: getHeaders(token) });
    return response.data;
  }
  catch (error) {
    console.log(error);
  }
}

async function deleteRequest(url: string, token: string) {
  try {
    const response = await axios.delete(url, { headers: getHeaders(token) });
    return response.data;
  }
  catch (error) {
    console.log(error);
  }
}

function getHeaders(token) {
  return process.env.ENVIRONMENT === 'LOCAL' ? { Authorization: `Bearer ${token}` } : { Authorization: `Bearer ${token}` };
}
